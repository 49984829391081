// ---------- Tiny Slider ----------
import {tns} from 'tiny-slider/src/tiny-slider';
import {debounce} from "debounce";
import Flickity from "flickity";
import 'element-closest';

let initiatedTinySliders = [];
let initiatedFlickitySliders = [];

export default {
	init({
		     sliderSelector = '[data-tiny-slider]',
		     attribute = 'data-tiny-slider',
		     slideSelector = '.slide'
	     } = {}) {

		let tinySliderEls = document.querySelectorAll(sliderSelector);
		tinySliderEls.forEach(tinySliderEl => {
			let slides = tinySliderEl.querySelectorAll(slideSelector);
			if (slides.length < 2) {
				return;
			}

			let autoHeight = false;

			let sliderOptions = {
				container: tinySliderEl,
				mode: 'carousel',
				controls: false,
				nav: true,
				autoplay: true,
				autoplayButton: false,
				autoplayButtonOutput: false,
				autoplayHoverPause: true,
				speed: 2000,
				autoplayTimeout: 5000,
				mouseDrag: false,
				arrowKeys: false,
				loop: true,
				freezable: true,
				animateIn: 'slide--fadeIn',
				animateOut: 'slide--fadeOut',
				animateNormal: 'slide--fadeNormal',
				autoHeight: false
			};

			let sliderType = tinySliderEl.getAttribute(attribute);
			let navSlider;
			let hasNavSlider = false;
			if (tinySliderEl.getAttribute('data-has-nav-slider') === 'true') {
				hasNavSlider = true;
			}

			if (sliderType === 'alt') {
				sliderOptions = {
					container: tinySliderEl,
					mode: 'gallery',
					controls: false,
					nav: false,
					autoplay: true,
					autoplayButton: false,
					autoplayButtonOutput: false,
					autoplayHoverPause: true,
					speed: 2000,
					autoplayTimeout: 5000,
					mouseDrag: false,
					arrowKeys: false,
					loop: false,
					freezable: true,
					animateIn: 'slide--fadeIn',
					animateOut: 'slide--fadeOut',
					animateNormal: 'slide--fadeNormal',
					autoHeight: false,
					rewind: true
				};
			} else if (sliderType === 'images') {
				sliderOptions = {
					container: tinySliderEl,
					mode: 'gallery',
					controls: false,
					nav: true,
					autoplay: true,
					autoplayButton: false,
					autoplayButtonOutput: false,
					autoplayHoverPause: true,
					speed: 2000,
					autoplayTimeout: 5000,
					mouseDrag: false,
					arrowKeys: false,
					loop: false,
					freezable: true,
					controlsText: ['<svg class="icon icon-arrow-left"><use xlink:href="#arrow-left"></use></svg>', '<svg class="icon icon-arrow-right"><use xlink:href="#arrow-right"></use></svg>'],
					animateIn: 'slide--fadeIn',
					animateOut: 'slide--fadeOut',
					animateNormal: 'slide--fadeNormal',
					autoHeight: false,
					rewind: true,
					responsive: {
						520 : {
							controls: true
						}
					}
				};
			} else if (sliderType === 'hero') {
				sliderOptions = {
					container: tinySliderEl,
					mode: 'carousel',
					controls: false,
					nav: true,
					autoplay: true,
					autoplayButton: false,
					autoplayButtonOutput: false,
					autoplayHoverPause: true,
					speed: 2000,
					autoplayTimeout: 4000,
					mouseDrag: false,
					arrowKeys: true,
					loop: false,
					rewind: true,
					freezable: true,
					controlsText: ['<svg class="icon icon-arrow-left"><use xlink:href="#arrow-left"></use></svg>', '<svg class="icon icon-arrow-right"><use xlink:href="#arrow-right"></use></svg>'],
					animateIn: 'slide--fadeIn',
					animateOut: 'slide--fadeOut',
					animateNormal: 'slide--fadeNormal',
					autoHeight: true,
					responsive: {
						1200 : {
							controls: true,
							nav: false
						}
					}
				};
				autoHeight = true;
			} else if (sliderType === 'testimonial') {
				sliderOptions = {
					container: tinySliderEl,
					mode: 'carousel',
					controls: false,
					nav: true,
					autoplay: true,
					autoplayButton: false,
					autoplayButtonOutput: false,
					autoplayHoverPause: true,
					speed: 1000,
					autoplayTimeout: 5000,
					mouseDrag: false,
					arrowKeys: false,
					loop: false,
					rewind: true,
					freezable: true,
					autoHeight: false
				};
				autoHeight = false;
			} else if (sliderType === 'products') {
				sliderOptions = {
					container: tinySliderEl,
					mode: 'carousel',
					controlsText: ['<svg class="icon icon-arrow-left"><use xlink:href="#arrow-left"></use></svg>', '<svg class="icon icon-arrow-right"><use xlink:href="#arrow-right"></use></svg>'],
					controls: false,
					nav: true,
					autoplay: false,
					autoplayButton: false,
					autoplayButtonOutput: false,
					autoplayHoverPause: true,
					speed: 1000,
					autoplayTimeout: 5000,
					mouseDrag: false,
					arrowKeys: false,
					loop: false,
					rewind: true,
					freezable: true,
					autoHeight: false,
					gutter: 32,
					responsive: {
						520 : {
							items: 2,
							slideBy: "page",
							controls: true
						},
						768 : {
							items: 3
						},
						1024 : {
							items: 4
						},
						1200 : {
							items: 5
						},
						1500 : {
							items: 6
						}
					}
				};
				autoHeight = false;
			}  else if (sliderType === 'productDetail') {
				sliderOptions = {
					container: tinySliderEl,
					mode: 'gallery',
					controls: false,
					nav: false,
					autoplay: false,
					autoplayButton: false,
					autoplayButtonOutput: false,
					autoplayHoverPause: true,
					speed: 1000,
					autoplayTimeout: 3000,
					mouseDrag: false,
					arrowKeys: false,
					loop: false,
					freezable: true,
					animateIn: 'slide--fadeIn',
					animateOut: 'slide--fadeOut',
					animateNormal: 'slide--fadeNormal',
					autoHeight: false
				};
				autoHeight = false;
			}

			let tinySlider = tns(sliderOptions);
			initiatedTinySliders.push(tinySlider);

			if (hasNavSlider) {
				let sliderGroup = tinySliderEl.closest('[data-slider-group]');
				if (sliderGroup) {
					//set up the nav slider
					navSlider = sliderGroup.querySelector('[data-nav-slider]');
					if (navSlider) {

						let tnsNavSlider = new Flickity( navSlider, {
							autoPlay: 5000,
							//cellAlign: 'left',
							contain: true,
							pageDots: false,
							prevNextButtons: false,
							wrapAround: false,
							draggable: false
						});
						initiatedFlickitySliders.push(tnsNavSlider);

						//change the gallery image
						tnsNavSlider.on( 'change', function( index ) {
							tinySlider.goTo(index);
						});

						//listen for clicks
						tnsNavSlider.on( 'staticClick', function( event, pointer, cellElement, cellIndex ) {
							tnsNavSlider.select(cellIndex);
							tinySlider.goTo(cellIndex);
						});
					}
				}
			}

			if (autoHeight) {
				//watch
				['DOMContentLoaded', 'load', 'resize'].forEach(function() {
					debounce(tinySlider.updateSliderHeight, 10, false);
				});
			}
		});
	},
	destroy() {
		initiatedTinySliders.forEach(initiatedSlider => {
			initiatedSlider.destroy();
		});
		initiatedTinySliders = [];

		initiatedFlickitySliders.forEach(initiatedSlider => {
			initiatedSlider.destroy();
		});
		initiatedFlickitySliders = [];
	}
}
