import Cookies from 'js-cookie';

export default {
	init({
		     selector = '[data-splash]',
		     hideClass = 'splash--hide',
		     showOnUnload = false,
		     oneTimeOnly = false,
		     displayTime = 2000,
		     storageKey = 'splash'
	     } = {}) {
		let splashes = document.querySelectorAll(selector);
		if (splashes && splashes.length) {
			Cookies.set(storageKey, 'y');
			splashes.forEach(splash => {
				if (displayTime) {
					setTimeout(() => {
						splash.classList.add(hideClass);
					}, displayTime);
				} else {
					splash.classList.add(hideClass);
				}
			});

			if (showOnUnload) {
				//show when the page is changing
				window.addEventListener('beforeunload', function(){
					splashes.forEach(splash => {
						splash.classList.remove(hideClass);
					});
				});
			}
		}
	}
}