import 'headjs/dist/1.0.0/head.load';

/**!
 * Instagram Embeds
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */

let isInstagramLoaded = false;

const initInstagram = isAlreadyInit => {
	isInstagramLoaded = true;
	if (!isAlreadyInit) {
		instgrm.Embeds.process();
	}
	window.sitePubSub.publish('instagramInitialized');
};

/**
 * Dynamically loads the Instagram script as needed.
 * https://www.instagram.com/developer/embedding/
 *
 * @param selector
 */
export default function (selector = '.embed--providerInstagram') {
	let els = document.querySelectorAll(selector);
	if (els.length) {
		if (!isInstagramLoaded) {
			head.load({ instagram: 'https://www.instagram.com/embed.js' }, ()=>initInstagram(true));
		} else {
			initInstagram(false);
		}
	}
}
