import Cookies from 'js-cookie';

let status = '';
let html = false;
let checkboxes = [];

let showDealerPricesClass = 'showWholesale';
let hideDealerPricesClass = 'showRetail';

let showDealerPrices = function() {
	if (status !== true) {
		html.classList.add(showDealerPricesClass);
		html.classList.remove(hideDealerPricesClass);
		Cookies.set('showDealerPrices', 'y');

		checkboxes.forEach(checkbox =>{
			checkbox.checked = true;
		});
	}
	status = true;
};

let hideDealerPrices = function() {
	if (status !== false) {
		html.classList.add(hideDealerPricesClass);
		html.classList.remove(showDealerPricesClass);
		Cookies.set('showDealerPrices', 'n');

		checkboxes.forEach(checkbox =>{
			checkbox.checked = false;
		});
	}
	status = false;
};

export default {
	init({selector = '.dealerPrices'} = {}) {

		html = document.querySelector('html');
		checkboxes = document.querySelectorAll(selector);

		if (html && checkboxes && checkboxes.length) {
			checkboxes.forEach(checkbox =>{
				checkbox.addEventListener('change', (event) => {
					if (event.target.checked) {
						showDealerPrices();
					} else {
						hideDealerPrices();
					}
				})
			});
		}
	}
}