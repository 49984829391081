let apps = [];

export default {
	init(selector = '[data-dealer-form]') {
		let dealerForms = document.querySelectorAll(selector);
		if (dealerForms.length) {
			//first let's load vue
			import(/*webpackChunkName: "vue"*/ 'vue').then(({default:Vue}) => {

				//now let's load the builder
				import(/*webpackChunkName: "dealerForm"*/ './DealerForm.vue').then(({default:DealerForm}) => {
					//mount the dealer form
					let Comp = Vue.extend(DealerForm);
					dealerForms.forEach(el => {
						let app = new Comp({
							propsData: {
								infoVar: el.getAttribute('data-info-var'),
								classes: el.getAttribute('data-classes')
							}
						}).$mount(el);
						apps.push(app);
					});
				});
			});
		}
	},
	destroy() {
		apps.forEach(app => {
			app.$destroy();
		});
		apps = [];
	}
}