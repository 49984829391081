let instances = [];

export default {
	init(selector = '[data-parallax]') {
		let sceneEls = document.querySelectorAll(selector);
		if (sceneEls.length) {
			import(/*webpackChunkName: "parallax"*/ 'parallax-js/src/parallax').then(({default:Parallax}) => {
				sceneEls.forEach(sceneEl => {
					let instance = new Parallax(sceneEl,{
						invertX: false,
						invertY: false,
						originX: 1,
						originY: 0.5,
						pointerEvents: true
						//calibrateX: 1,
						//calibrateY: 0.5,
						//relativeInput: true
					});

					instances.push(instance);
				});
			});
		}
	},
	destroy() {
		instances.forEach(instance => {
			if (instance) {
				instance.destroy();
			}
		});
		instances = [];
	}
}