import simpleStorage from 'simplestorage.js';

let cartLinks;
let storageKey;

const updateCartLinks = function() {
	if (cartLinks.length) {
		let cartCount = simpleStorage.get(storageKey);
		cartLinks.forEach(cartLink => {
			if (cartLink) {
				cartLink.innerHTML = (cartCount && cartCount > 0) ? cartCount : '';
			}
		});
	}
};

export default {
	init({
	       selector = '.nav-cart > span',
			key = 'cartCount'
	   } = {}) {
		cartLinks = document.querySelectorAll(selector);
		storageKey = key;

		updateCartLinks();

		sitePubSub.subscribe('cartUpdate', updateCartLinks);
	}
}
