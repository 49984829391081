import 'headjs/dist/1.0.0/head.load';

/**!
 * Twitter Embeds
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */
let isTwitterLoaded = false;

const initTwitter = isAlreadyInit => {
	isTwitterLoaded = true;
	if (!isAlreadyInit) {
		twttr.widgets.load();
	}
	window.sitePubSub.publish('twitterInitialized');
};

/**
 * Dynamically loads the twitter widget as needed
 *
 * @param selector
 */
export default function (selector = '.embed--providerTwitter') {
	let els = document.querySelectorAll(selector);
	if (els.length) {
		if (!isTwitterLoaded) {
			head.load({ twitter: 'https://platform.twitter.com/widgets.js' }, ()=>initTwitter(true));
		} else {
			initTwitter(false);
		}
	}
}
