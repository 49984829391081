import {debounce} from 'debounce';

/**!
 * Mobile Menu
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */

export default class MobileMenu {
	html = document.querySelector('html');
	mobileMenuIsOpen = false;
	openedClass = 'mobileMenuOpen';
	closedClass = 'mobileMenuClosed';
	onOpenCallback = function() {};
	onCloseCallback = function() {};

	constructor({
		            openClass = 'mobileMenuOpen',
		            closeClass = 'mobileMenuClosed',
		            maxWidth = 0,
		            toggleSelector = null,
		            openSelector = null,
		            closeSelector = null,
		            onOpen = function(){},
		            onClose = function(){}
	            } = {}) {

		//override the defaults, if applicable
		this.openedClass = openClass;
		this.closedClass = closeClass;
		this.onOpenCallback = onOpen;
		this.onCloseCallback = onClose;

		//set up the mobile menu toggles
		if (toggleSelector) {
			let mobileMenuToggles = document.querySelectorAll(toggleSelector);
			mobileMenuToggles.forEach(mobileMenuToggle => {
				mobileMenuToggle.addEventListener('click', e => {
					e.preventDefault();
					this.mobileMenuIsOpen = !this.mobileMenuIsOpen;
					this.toggle();
				});
			});
		}

		//set up the mobile menu openers
		if (openSelector) {
			let mobileMenuOpeners = document.querySelectorAll(openSelector);
			mobileMenuOpeners.forEach(mobileMenuOpener => {
				mobileMenuOpener.addEventListener('click', e => {
					e.preventDefault();
					this.open()
				});
			});
		}

		//set up the mobile menu openers
		if (closeSelector) {
			let mobileMenuClosers = document.querySelectorAll(closeSelector);
			mobileMenuClosers.forEach(mobileMenuCloser => {
				mobileMenuCloser.addEventListener('click', e => {
					e.preventDefault();
					this.close();
				});
			});
		}

		if (maxWidth) {
			const closeIfPastMaxWidth = () => {
				let w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
				if (w >= maxWidth) {
					this.close();
				}
			};

			['DOMContentLoaded', 'load', 'resize'].forEach(function(eventName) {
				window.addEventListener(eventName, debounce(closeIfPastMaxWidth, 10, false));
			});

			//initial
			closeIfPastMaxWidth();
		}
	}

	open() {
		this.mobileMenuIsOpen = true;
		this.html.classList.add(this.openedClass);
		this.html.classList.remove(this.closedClass);
		if (this.onOpenCallback) {
			this.onOpenCallback();
		}
	}

	close() {
		this.mobileMenuIsOpen = false;
		this.html.classList.add(this.closedClass);
		this.html.classList.remove(this.openedClass);
		if (this.onCloseCallback) {
			this.onCloseCallback();
		}
	};

	toggle() {
		if (this.mobileMenuIsOpen) {
			this.open();
		} else {
			this.close();
		}
	};
}
