// ---------- Flickity Slider ----------
import Flickity from 'flickity';
import 'flickity-as-nav-for';

let initiatedSliders = [];

export default {
	init({
		     sliderSelector = '[data-flickity-slider]',
		     attribute = 'data-flickity-slider',
	     } = {}) {

		let flickitySliders = document.querySelectorAll(sliderSelector);
		flickitySliders.forEach(flickitySlider => {
			let sliderType = flickitySlider.getAttribute(attribute);
			if (sliderType === 'products') {
				//set up the main slider
				let initiatedSlider = new Flickity(flickitySlider, {
					arrowShape: 'M71.875,100l6.25,-6.25l-43.75,-43.75l43.75,-43.75l-6.25,-6.25l-50,50l50,50Z',
					autoPlay: 3000,
					//cellAlign: 'left',
					contain: true,
					pageDots: false,
					prevNextButtons: false,
					wrapAround: false
				});

				initiatedSliders.push(initiatedSlider);
			}
		});
	},
	destroy() {
		initiatedSliders.forEach(initiatedSlider => {
			initiatedSlider.destroy();
		});
		initiatedSliders = [];
	}
}
