let apps = [];

export default {
	init(selector = '[data-resellers]') {
		let resellersEls = document.querySelectorAll(selector);
		if (resellersEls.length) {
			//first let's load vue
			import(/*webpackChunkName: "vue"*/ 'vue').then(({default:Vue}) => {

				//now let's load the resellers
				import(/*webpackChunkName: "resellers"*/ './Resellers.vue').then(({default:Resellers}) => {
					//mount the resellers
					let Comp = Vue.extend(Resellers);
					resellersEls.forEach(el => {
						let app = new Comp({
							propsData: {
								apiKey: el.getAttribute('data-api-key'),
								infoVar: el.getAttribute('data-info-var'),
								styleVar: el.getAttribute('data-style-var')
							}
						}).$mount(el);
						apps.push(app);
					});
				});
			});
		}
	},
	destroy() {
		apps.forEach(app => {
			app.$destroy();
		});
		apps = [];
	}
}