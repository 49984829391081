import 'headjs/dist/1.0.0/head.load';

/**!
 * Facebook Embeds
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */

let isFacebookLoaded = false;

/**
 * Create an element from HTML.
 *
 * @see https://stackoverflow.com/a/494348/1136822
 * @param {string} htmlString
 * @return {node|null}
 */
const createElementFromHTML = function(htmlString) {
	let div = document.createElement('div');
	div.innerHTML = htmlString.trim();

	// Change this to div.childNodes to support multiple top-level nodes
	return div.firstChild;
};

window.fbAsyncInit = () => {
	FB.init({
		//appId            : 'your-app-id',
		//autoLogAppEvents : true,
		xfbml            : true,
		version          : 'v3.2'
	});
};

const initFacebook = isAlreadyInit => {
	isFacebookLoaded = true;
	if (!isAlreadyInit) {
		FB.XFBML.parse();
	}

	window.sitePubSub.publish('facebookInitialized');
};

/**
 * Dynamically loads the Facebook script as needed.
 * https://developers.facebook.com/docs/plugins/embedded-posts
 *
 * @param selector
 */
export default function (selector = '.embed--providerFacebook') {
	let els = document.querySelectorAll(selector);
	if (els.length) {
		if (!isFacebookLoaded) {
			let el = createElementFromHTML('<div id="fb-root"></div>');
			document.body.appendChild(el);
			head.load({ facebook: 'https://connect.facebook.net/en_US/sdk.js' }, ()=>initFacebook(true));
		} else {
			initFacebook(false);
		}
	}
}